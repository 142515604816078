<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <!-- ***************************** Overview *********************************** -->
        <section id="overview">
          <div class="bsat__paragraph">{{ $t("overview.paragraph") }}</div>
          <ReadMore>
            <div class="bsat__paragraph">{{ $t("overview.paragraph2") }}</div>
          </ReadMore>
        </section>

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

            <div class="bsat__paragraph">
              {{ $t("implementationRecommendations.paragraph") }}
            </div>
          </ReadMore>
        </section>

        <!-- ********************* RECOMMENDED RESOURCES ********************* -->
        <section id="recommendedResources">
          <div class="steps__subtitle bsat__subtitle my-4">
            {{ $t("recommendedResources.title") }}
          </div>

          <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div>

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <div class="bsat__paragraph">
                {{ $t("recommendedResources.paragraph") }}
              </div>
            </li>

            <li>
              <i18n
                path="recommendedResources.paragraph2.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link1>
                  <a @click="goToTab(2)">{{
                    $t("recommendedResources.paragraph2.link1")
                  }}</a>
                </template>

                <template #link2>
                  <a @click="goToTab(3)">{{
                    $t("recommendedResources.paragraph2.link2")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <i18n
                path="recommendedResources.paragraph3.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToStep(3)">{{
                    $t("recommendedResources.paragraph3.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <i18n
                path="recommendedResources.paragraph4.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToStep(1)">{{
                    $t("recommendedResources.paragraph4.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <i18n
                path="recommendedResources.paragraph5.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToStep(2)">{{
                    $t("recommendedResources.paragraph5.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <!-- <li>
              <div class="bsat__paragraph">
                {{ $t("recommendedResources.paragraph6") }}
              </div>
            </li> -->
          </ul>
        </section>

        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
	"en": {
		"implementationRecommendations": {
			"paragraph": "Collecting consultation feedback and analysing the most relevant ones with decision-level of Interministerial/interdepartmental group and advisory group. Including approved changes to the final draft.",
			"proposedMethods": {
				"title": "Proposed methods",
				"unorderedList": [
					"Sending final draft per email. ",
					"Uploading final draft on regional ministries or governmental department´s web pages. ",
					"Other established channels for dissemination"
				]
			},
			"task": {
				"text": "{title}: Final regional bioeconomy strategy and roadmap",
				"title": "Task"
			},
			"taskLeader": {
				"text": "{title}: Strategy update working group.",
				"title": "Task leader"
			},
			"title": "Implementation Recommendations"
		},
		"overview": {
			"paragraph": "After having a final draft of the updated bioeconomy strategy, a consultation process with key external advisors and key engaged stakeholders interested in the expected results and impacts of the new strategic document(s) is recommended. It will allow to collect a final feedback and to enhance the recognition of the strategy. The consultation might be requested directly, sending the final draft to specific institutions, and asking for their feedback.  This exercise, involving a limited number of contacted colleagues, might be complemented by an open public consultation to the public. The latter would require a dissemination plan to reach out to a larger audience, using usual channels, such as regional administrations web pages, newsletters, between others.",
			"paragraph2": "As the case may be, diverse stakeholders might be consulted on the final draft of the updated regional bioeconomy strategy and roadmap such as, NGO´s, universities, technical parks, other R&D institutions, Start-ups and SME´s, big industries and investors. After a prudential consultation time (usually around a month or more), final comments and feedback are collected, analysed and integrated into the final draft, after internal agreement between Interministerial/interdepartmental group and advisory group. The final regional bioeconomy strategy and final roadmap might then be ratified by the regional administration."
		},
		"recommendedResources": {
			"paragraph": "Feedback from regional stakeholders.",
			"paragraph2": {
				"link1": "Phase 2",
				"link2": "Phase 3",
				"text": "Regional bioeconomy vision ({link1}) Determined priority areas and their main goals ({link2})"
			},
			"paragraph3": {
				"link": "Step 3",
				"text": "Updated priority areas, main and specific goals ({link})"
			},
			"paragraph4": {
				"link": "Step 1",
				"text": "Updated roadmap ({link})"
			},
			"paragraph5": {
				"link": "Step 2",
				"text": "Assigned policy and financial mechanisms for the planned actions ({link})"
			},
			"paragraph6": "Feedback from participatory approach",
			"title": "Recommended resources"
		},
		"title": "Step 4: Updated regional bioeconomy strategy and updated roadmap ",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
	}
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import { EventBus } from "@/utils/EventBus";

export default {
  name: "UpdatePhase4Step4",
  components: {
    ReadMore
  },
  data: () => ({
    zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
  }),
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    }
  },
  methods: {
    goToTab(tab) {
      window.scroll({ top: 0 });
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      window.scroll({ top: 200 });
      EventBus.$emit("goToStep", step);
    },
    getSummaryChildren(obj) {
      return Object.values(obj);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>
